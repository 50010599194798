<template>
  <span>


    <b-modal no-close-on-backdrop scrollable title="Create Intel Case" class="modal-dark" v-model="createCaseModal"
             role="dialog">
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label>{{ fields.caseName.name }}</label>
            <InlineSearch :form-control="filledOut(caseData.caseName)"
                          v-model="caseData.caseName" href-info="Intel Case Details"
                          data-type="caseName" :arrow="false" id-name="intelCaseId"
                          :data="this.cases" :upper-case="true"/>
        </b-form-group>
      </b-col>
        <!-- <b-col sm="4">
           <b-form-group>
             <label>{{fields.caseNumber.name}}</label>
             <b-form-input type="number" v-model.number="caseData.caseNumber"/>
           </b-form-group>
         </b-col>-->
      <b-col sm="12">
        <b-form-group>
          <label>{{ fields.status.name }}</label>
          <b-form-select :class=" 'form-control is-' + (filledOut(caseData.status) ? 'valid':'invalid')"
                         :options="['OPEN', 'CLOSED', 'ON HOLD']" v-model="caseData.status"/>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group>
          <label>{{ fields.description.name }}</label>
            <!--<b-form-input v-model="caseData.description"/>-->
          <textarea class="form-control rounded-0" rows="4" v-model="caseData.description" type="text"></textarea>
        </b-form-group>
      </b-col>
        <!--<b-col sm="12">
          <label>{{fields.addresses.name}}</label>&nbsp;<i @click="push(caseData.addresses, fields.addresses.format)" class="fa fa-plus-circle fa-md"/>
          <b-list-group v-for="(e,i) in caseData.addresses" :key="i"  v-if="refresh">
            <b-list-group-item>
              <strong>Addresses #{{i+1}}</strong>
              <i style="float:right" class="fa fa-close fa-sm" @click="splice(i, caseData.addresses)" v-if="caseData.addresses.length > 1"/>
              <b-input-group class="input-group-sm" prepend="Address Line 1" style="margin-bottom: 1px">
                <b-form-input v-model="caseData.addresses[i].address"/>
              </b-input-group>
              <b-input-group class="input-group-sm" prepend="Address Line 2" style="margin-bottom: 1px">
                <b-form-input v-model="caseData.addresses[i].address2"/>
              </b-input-group>
              <b-input-group prepend="City" class="input-group-sm">
                <b-form-input v-model="caseData.addresses[i].city"/>
                <b-input-group-append class="input-group-sm"><b-input-group-text>State</b-input-group-text></b-input-group-append>
                <b-form-select size="sm" :options="states" v-model="caseData.addresses[i].state"/>
                <b-input-group-append class="input-group-sm"><b-input-group-text>Zip</b-input-group-text></b-input-group-append>
                <b-form-input type="number" v-model="caseData.addresses[i].zip"/>
              </b-input-group>
              <b-input-group prepend="Notes" class="input-group-sm">
                <b-form-input size="sm" v-model="caseData.addresses[i].notes"/>
              </b-input-group>
            </b-list-group-item>
          </b-list-group><br>
        </b-col>-->
        <!--<b-col sm="12">
          {{profiles.data}}
          <v-client-table style="width: 100%" :columns="profiles.columns" :data="profiles.data" :options="profiles.tOptions" :theme="profiles.theme" class="dataTable">
            <b-form-checkbox slot-scope="props" slot="check" v-model="profiles.select.selected" :value="props.row.profileId"></b-form-checkbox>
            <div slot="h__check">
              <b-form-checkbox type="checkbox" style="padding-left: 24px" class="check-all" v-model='profiles.select.allMarked' @change="profiles.select.selected = unmarkAndMarkAll(profiles.select.selected, profiles.select.allMarked)"></b-form-checkbox>
            </div>
          </v-client-table>
        </b-col>-->
      <b-col sm="12">
        <b-form-group>
          <label>Other Notes</label>&nbsp;
            <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;"
                     @click="push(caseData.notes, fields.notes.format)"/>
          <b-input-group v-for="(e,i) in caseData.notes" :key="i" v-if="refresh">
            <b-form-input v-model="e[i].text"/>
            <b-input-group-append v-if="e.length > 1">
              <b-button variant="dark" @click="splice(i, e)">
                <fa-icon :icon="['fas', 'close']"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
      <template slot="modal-footer">
        <b-button size="sm" variant="secondary" @click="createCaseModal = false">Cancel</b-button>
        <b-button size="sm" variant="primary" :disabled="disableIntelSubmit() "
                  @click="postCase(caseData)">Submit</b-button>
      </template>
    </b-modal>

      <!--Main Page-->
    <b-card style="width: 100%; height: 100%">
      <div slot="header">
        <span class="d-flex w-100 justify-content-between">
          <h5 style="margin-bottom: 0"><b>Intel Case Overview</b></h5>
          <fa-icon :icon="['fas', 'square-plus']" size="lg" style="cursor: pointer;" @click="openCreateIntelCase()"/>
        </span>
      </div>
      <v-client-table style="width: 100%" :columns="caseTable.columns" :data="cases"
                      :options="caseTable.tOptions" :theme="caseTable.theme" class="dataTable">
        <span slot="h__info" style="float: right">
          Info
        </span>
        <div slot="description" slot-scope="props">
            <span v-if="props.row.description && props.row.description.length > 193">
                {{ props.row.description.substring(0, 193) }}...
            </span>
            <span v-else>{{ props.row.description }}</span>
        </div>
        <span slot="info" slot-scope="props" style="float: right">
          <router-link class="non-link" :to="{ name: 'Intel Case Details', params: {id: props.row.intelCaseId}}">
            <b-button style="float: right; color: #20a8d8; background-color: #2a2a2a;"
                      size="sm" variant="dark">
                <fa-icon :icon="['fas', 'info']" size="lg"/>
            </b-button>
          </router-link>
        </span>
      </v-client-table>
    </b-card>

  </span>
</template>

<script>
import risc from '@/services/risc.js'
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import InlineSearch from "../InlineSearch";

Vue.use(ClientTable)

export default {
    name: "IntelCases",

    components: {
        InlineSearch,
    },

    data: () => {
        return {
            cases: [],
            caseTable: {
                columns: ['caseNumber', 'caseName', 'status', 'description', 'info'],
                tOptions: {
                    sortable: ['caseNumber', 'caseName', 'status', 'description'],
                    filterable: ['caseNumber', 'caseName', 'status', 'description'],
                    orderBy: {column: 'caseName', ascending: true},
                    headings: {
                        caseNumber: 'Intel Case Number',
                        caseName: 'Case Name',
                        description: 'Description',
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
            },
            createCaseModal: false,
            caseData: {},
            refresh: true,
            fields: {
                caseName: {field: 'caseName', name: 'Case Name', required: true},
                status: {field: 'status', name: 'Status', required: false},
                intelCaseNumber: {field: 'intelCaseNumber', name: 'Intel Case Number ', required: true},
                description: {field: 'description', name: 'Description', required: false},
                /*addresses: {field: 'addresses', name: 'Addresses', required: false, format: {address1: '', address2: '', city: '', state:'', zip:'', notes:''}},*/
                notes: {field: 'notes', name: 'Notes', required: false, format: {text: ""}},
            },
            states: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
            ],
            profiles: {
                data: [],
                select: {
                    selected: [],
                    allMarked: false,
                },
                columns: ['check', 'name', 'DOB', 'alias'],
                tOptions: {
                    sortable: ['name', 'DOB', 'alias'],
                    filterable: ['name', 'DOB', 'alias'],
                    orderBy: {column: 'name', ascending: true},
                    headings: {},
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
            },
        }
    },

    created() {
        this.getCases();
    },

    methods: {
        openCreateIntelCase() {
            risc.getProfiles().then(response => {
                response.data.forEach((profile, index) => {
                    this.profiles.data[index] = {}
                    this.profiles.data[index].name = this.nameify(profile)
                    this.profiles.data[index].DOB = this.dateify(profile.birthMonth, profile.birthDay, profile.birthYear)
                    this.profiles.data[index].alias = ""
                    this.profiles.data[index].profileId = profile.profileId
                    if (Array.isArray(profile.alias)) {
                        profile.alias.forEach((e, i) => {
                            this.profiles.data[index].alias += e.knownAlias + (profile.alias.length === i + 1 ? "" : ", ")
                        })
                    }
                })
                this.caseData = {
                    notes: [JSON.parse(JSON.stringify(this.fields.notes.format))]
                }
                this.createCaseModal = true;
            })
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '');
        },

        disableIntelSubmit() {
            return !this.filledOut(this.caseData.caseName) ||
                !this.filledOut(this.fields.status.name);

        },
        clean() {
            Object.keys(this.caseData).forEach(function (data, index) {
                if (data[1].isArray() && this.fields[data[0]].format && this.fields[data[0]].format === data[1]) {
                    let objectUrl = data[1] + '[' + index + ']'
                    delete this.caseData[objectUrl] //caseData.<type>.<index of array>
                }
            })
        },
        postCase(data) {
            risc.postCase(data).then(() => {
                /*this.profiles.select.selected.forEach(profileId => {
                  risc.linkProfileCase(data.intelCaseId, profileId).then(() => {
                    this.getCases()
                  })*/
                this.getCases()
                this.createCaseModal = false
            })
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
            this.refresh = false;
            this.refresh = true;

        },
        splice(index, field) {
            field.splice(index, 1);
            this.refresh = false;
            this.refresh = true;
        },
        nameify(profile) {
            if (profile.firstName && profile.lastName && profile.middleName) {
                return (profile.lastName + ', ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName && profile.lastName) {
                return (profile.lastName + ', ' + profile.firstName)
            } else if (profile.firstName && profile.middleName) {
                return ('Unknown, ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.lastName && profile.middleName) {
                return (profile.lastName + ', Unknown ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName) {
                return ('Unknown, ' + profile.firstName)
            } else if (profile.lastName) {
                return (profile.lastName + ', Unknown ')
            } else if (profile.middleName) {
                return ('Unknown, Unknown ' + profile.middleName.slice(0, 1))
            }
        },
        dateify(month, day, year) {
            let result = "";
            result += (!month ? 'XX' : (month < 10 ? "0" + month : month)) + '/';
            result += (!day ? 'XX' : (day < 10 ? "0" + day : day)) + '/';
            result += (!year ? 'XXXX' : year);
            return result;
        },
        unmarkAndMarkAll(selected, allMarked) {
            if (allMarked === false) {
                let temp = [];
                if (this.$refs.dTable.allFilteredData) {
                    this.$refs.dTable.allFilteredData.forEach(function (item) {
                        temp.push(item.profileId);
                    });
                }
                return (temp)
            } else {
                return ([])
            }
        },
        getCases() {
            risc.getCases().then(cases => {
                this.cases = cases.data;
            })
        },
    }
}
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>